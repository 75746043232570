<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
      :show-export-excel="true"
      @excel="exportRawExcel"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #departmentId="item">
          <b-form-group :label-for="item.field.key">
            <label>
              {{ $t(item.field.label) }}
            </label>
            <n-async-single-select
              v-model="params[item.field.key]"
              :query="{
                limit: 0,
                company_id: params.companyId,
              }"
              :ref="item.field.key"
              :name="item.field.key"
              :repository="item.field.repository"
              :selection-key="item.field.selectionKey"
              :selection-label="item.field.selectionLabel"
            ></n-async-single-select>
          </b-form-group>
        </template>
        <template #employeeId="item">
          <b-form-group :label-for="item.field.key">
            <label>
              {{ $t(item.field.label) }}
            </label>
            <n-async-single-select
              v-model="params[item.field.key]"
              :query="{
                limit: 0,
                company_id: params.companyId,
                department_id: params.departmentId,
              }"
              :ref="item.field.key"
              :name="item.field.key"
              :repository="item.field.repository"
              :selection-key="item.field.selectionKey"
              :selection-label="item.field.selectionLabel"
            ></n-async-single-select>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', resource)">
            <b-button variant="primary" :to="{ name: `create-${route}` }">{{
              $t("button.create")
            }}</b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          @sort-changed="sortChanged"
          @destroy="destroy"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :deletable="true"
          :resource="resource"
          :route="route"
          primary-field="title"
        >
          <template #cell(profile)="data">
            <b-avatar
              class="cursor-pointer"
              variant="light"
              size="50px"
              :src="
                data.item.employee.profile ||
                require('@/assets/images/avatars/profile.jpeg')
              "
              alt="profile"
            />
          </template>
          <template #cell(employee)="data">
            <span v-if="data.item.employee">
              {{ data.item.employee.name }}
            </span>
          </template>
          <template #cell(employeeIdCard)="data">
            <span v-if="data.item.employee">
              {{ data.item.employee.idCard }}
            </span>
          </template>
          <template #cell(company)="data">
            <span v-if="data.item.employee.companyId">
              {{ data.item.employee.company.name }}
            </span>
          </template>
          <template #cell(department)="data">
            <span v-if="data.item.employee.departmentId">
              {{ data.item.employee.department.name }}
            </span>
          </template>
          <template #cell(position)="data">
            <span v-if="data.item.employee.positionId">
              {{ data.item.employee.position.name }}
            </span>
          </template>
          <template #cell(state)="data">
            <b-badge
              pill
              :variant="data.item.stateColor"
              badge-glow
              v-if="data.item.state"
            >
              {{ $t(`state.${data.item.stateName}`) }}
            </b-badge>
          </template>
          <template #cell(midYearState)="data">
            <b-badge pill :variant="data.item.midYearStateColor" badge-glow>
              {{ $t(`state.${data.item.midYearStateName}`) }}
            </b-badge>
          </template>
          <template #cell(yearEndState)="data">
            <b-badge pill :variant="data.item.yearEndStateColor" badge-glow>
              {{ $t(`state.${data.item.yearEndStateName}`) }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`field.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`field.inactive`) }}
            </b-badge>
          </template>
          <template #cell(createdBy)="data">
            <span v-if="data.item.createdBy">
              {{ data.item.createdBy.name }}
            </span>
          </template>
          <template #cell(updatedBy)="data">
            <span v-if="data.item.updatedBy">
              {{ data.item.updatedBy.name }}
            </span>
          </template>

          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                :to="{
                  name: `review-${route}`,
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.review')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
              <b-button
                v-if="$can('update', resource)"
                :to="{
                  name: `update-${route}`,
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="$can('clone', resource)"
                :to="{
                  name: `clone-${route}`,
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.clone')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BAvatar, BBadge, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import { avatarText, formatDate } from "@core/utils/filter";
import { getState } from "@/libs/helper";
import moment from "moment";

const OkrSettingRepository = Repository.get("okrSetting");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,
    BFormGroup,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NAsyncSingleSelect,
    NTable,
    vSelect,
  },
  watch: {
    "params.companyId"(newValue, oldValue) {
      this.$refs.departmentId.clear();
      this.$refs.employeeId.clear();
    },
    "params.departmentId"(newValue, oldValue) {
      this.$refs.employeeId.clear();
    },
    perPage() {
      this.list();
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        companyId: Number(this.$route.query.companyId) || null,
        employeeId: Number(this.$route.query.employeeId) || null,
        year: this.$route.query.year || null,
        state: this.$route.query.state || null,
        midYearState: this.$route.query.midYearState || null,
        yearEndState: this.$route.query.yearEndState || null,
        isEnable: this.$route.query.isEnable || null,
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        companyId: Number(this.$route.query.companyId) || null,
        departmentId: Number(this.$route.query.departmentId) || null,
        employeeId: Number(this.$route.query.employeeId) || null,
        year: this.$route.query.year || null,
        state: this.$route.query.state || null,
        midYearState: this.$route.query.midYearState || null,
        yearEndState: this.$route.query.yearEndState || null,
        isEnable: this.$route.query.isEnable || null,
      },
      a: null,
      avatarText,
    };
  },
  mounted() {
    this.a = document.createElement("a");
    document.body.appendChild(this.a);
    this.a.style = "display: none";

    this.getData();
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    list(page = 1) {
      this.query.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: this.searchFields,
      };
      OkrSettingRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];

            this.items.forEach((item) => {
              var stateString = getState(item.state);
              item.stateName = stateString.name;
              item.stateColor = stateString.color;

              var midYearState =
                item.midYearReview !== null ? item.midYearReview.state : "";
              var midYearStateString = getState(midYearState);
              item.midYearStateName = midYearStateString.name;
              item.midYearStateColor = midYearStateString.color;

              var yearEndState =
                item.yearEndReview !== null ? item.yearEndReview.state : "";
              var yearEndStateString = getState(yearEndState);
              item.yearEndStateName = yearEndStateString.name;
              item.yearEndStateColor = yearEndStateString.color;
            });

            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    destroy(id) {
      OkrSettingRepository.destroy(id)
        .then((response) => {
          this.getData();
        })
        .catch((error) => {});
    },
    exportRawExcel() {
      this.loading = true;
      const params = {
        ...this.query,
      };

      OkrSettingRepository.exportRawExcel(params)
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `OKRs Year-End Review ${moment().format("DD-MMM-YYYY")}.xlsx`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "okr-setting";
    const route = "okr-setting";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
