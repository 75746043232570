export default [
  {
    key: "companyId",
    label: "field.company",
    rules: "required",
    type: "nAsynSingleSelection",
    repository: "company",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "departmentId",
    label: "field.department",
    rules: "required",
    type: "nAsynSingleSelection",
    repository: "department",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "employeeId",
    label: "field.employee",
    rules: "required",
    type: "nAsynSingleSelection",
    repository: "employee",
    selectionKey: "id",
    selectionLabel: "nameLabel",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "year",
    label: "field.year",
    type: "nYearPicker",
  },
  {
    key: "state",
    label: "field.state",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.submitted", value: "submitted" },
      { text: "field.approved", value: "approved" },
      { text: "field.hodApproved", value: "hodapproved" },
      { text: "field.gmApproved", value: "gmapproved" },
      { text: "field.hrApproved", value: "hrapproved" },
      { text: "field.rejected", value: "rejected" },
      { text: "field.hodRejected", value: "hodrejected" },
      { text: "field.gmRejected", value: "gmrejected" },
      { text: "field.hrRejected", value: "hrrejected" },
    ],
    lg: "auto",
  },
  {
    key: "midYearState",
    label: "field.midYearState",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      // { text: "field.notStarted", value: "notstarted" },
      { text: "field.submitted", value: "submitted" },
      { text: "field.approved", value: "approved" },
      { text: "field.hodApproved", value: "hodapproved" },
      { text: "field.gmApproved", value: "gmapproved" },
      { text: "field.hrApproved", value: "hrapproved" },
      { text: "field.rejected", value: "rejected" },
      { text: "field.hodRejected", value: "hodrejected" },
      { text: "field.gmRejected", value: "gmrejected" },
      { text: "field.hrRejected", value: "hrrejected" },
    ],
    lg: "auto",
  },
  {
    key: "yearEndState",
    label: "field.yearEndState",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      // { text: "field.notStarted", value: "notstarted" },
      { text: "field.submitted", value: "submitted" },
      { text: "field.approved", value: "approved" },
      { text: "field.hodApproved", value: "hodapproved" },
      { text: "field.gmApproved", value: "gmapproved" },
      { text: "field.hrApproved", value: "hrapproved" },
      { text: "field.managementApproved", value: "managementapproved" },
      { text: "field.rejected", value: "rejected" },
      { text: "field.hodRejected", value: "hodrejected" },
      { text: "field.gmRejected", value: "gmrejected" },
      { text: "field.hrRejected", value: "hrrejected" },
      { text: "field.managementRejected", value: "managementrejected" },
    ],
    lg: "auto",
  },
  {
    key: "isEnable",
    label: "field.status",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.active", value: "1" },
      { text: "field.inactive", value: "0" },
    ],
    lg: "auto",
  },
];
